import React from 'react'
export const Button = ({ children, className }) => (
	<button className={`grow justify-center rounded-2xl shadow-sm w-fit ${className}`} tabIndex={0}>
		{children}
	</button>
);
function MainBanner() {
  return (
    <main className="flex overflow-hidden relative flex-col items-start px-14 py-20 w-full font-black text-yellow-100 min-h-[661px] max-md:px-5 max-md:max-w-full">
    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/ea9682f1a08dd54c302d61a0924b1d744f71189b44d4d4e69e6c173705e42836?apiKey=9db28024b9de495c90b2cacb6d403710&" alt="" className="object-cover absolute inset-0 size-full" />
    <section className="relative mt-9 text-6xl  max-md:max-w-full max-md:text-4xl text-stroke-red">
        <h2>Join the Pack: <br /> Top Dog to Rule <br /> Them All ($TPDG)</h2>
    </section>
    <p className="relative justify-center px-4 py-4 mt-7 max-w-full text-xl font-bold capitalize rounded-3xl w-[563px] max-md:max-w-full descTop">
        Embrace the future of meme coins with $TPDG <br />
        the loyal and alert companion in the crypto world.
    </p>
    {/* <div className="flex relative gap-5 mt-8 mb-1.5 text-2xl text-indigo-950 max-md:flex-wrap">
        <Button className="py-4 px-8 BuyTPDG">Buy $TPDG</Button>
        <Button className="py-4 px-8 BuyTPDG">Learn More</Button>
    </div> */}
</main>
  )
}

export default MainBanner