import React from 'react'

function SocialLinks() {
    return (
        <section className="flex gap-5 justify-between items-center self-center px-5 mt-12 max-md:flex-wrap max-md:mt-10">
            <a href="https://x.com/tpdgwtf" className="hover-transform" target='_blank' rel="noreferrer">
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/b4a4e96e706fd0544f6546e879afa9fb4dad6fa7d92cf6482ede727565198078?apiKey=9db28024b9de495c90b2cacb6d403710&"
                    alt=""
                    className="shrink-0 self-start mt-2 max-w-full aspect-[1.23] w-[122px]"
                />
            </a>
            <a href="https://t.me/topdog" className="hover-transform" target='_blank' rel="noreferrer">
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/03c8c58a28b1b5c8400a51429a722176d7b219abc23a37c08773cc57615e0c2d?apiKey=9db28024b9de495c90b2cacb6d403710&"
                    alt=""
                    className="shrink-0 self-stretch my-auto aspect-square w-[98px]"
                />
            </a>
            {/* <a href="#" className="hover-transform">
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/a30d9c329fa7874d3a6a4404a3d027fbdd2cfbe9b220b5f40487cf19d5b7178c?apiKey=9db28024b9de495c90b2cacb6d403710&"
                    alt=""
                    className="shrink-0 self-stretch my-auto aspect-square w-[98px]"
                />
            </a>
            <a href="#" className="hover-transform">
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/cbb2fc5e7e3c1cfee3d4a605479c64cd855ea5fea6ab864014176d7c7c9a0122?apiKey=9db28024b9de495c90b2cacb6d403710&"
                    alt=""
                    className="shrink-0 self-stretch my-auto max-w-full aspect-square w-[100px]"
                />
            </a>
            <a href="#" className="hover-transform">
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/d5fe77fcb0b8301c8dc931357812baee8678bf7127de8e34211a029d9c4d9977?apiKey=9db28024b9de495c90b2cacb6d403710&"
                    alt=""
                    className="shrink-0 self-stretch max-w-full aspect-square w-[108px]"
                />
            </a>
            <a href="#" className="hover-transform">
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/0744c67244140c60d7e40e6d98f5e56271bef9915c013efc305303603561e53f?apiKey=9db28024b9de495c90b2cacb6d403710&"
                    alt=""
                    className="shrink-0 self-stretch my-auto max-w-full aspect-[0.99] w-[100px]"
                />
            </a>
            <a href="#" className="hover-transform">
                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/7f6aede5eeeff79e6db3486dc8cdf6aad70f979d939015c26e81062079eeed45?apiKey=9db28024b9de495c90b2cacb6d403710&"
                    alt=""
                    className="shrink-0 self-stretch my-auto aspect-[0.87] w-[87px]"
                />
            </a> */}
        </section>
    )
}

export default SocialLinks