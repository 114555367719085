import React, { useEffect, useState } from 'react'
import { Button } from './MainBanner'
import copy from '../assets/imgs/copy.svg'
import EarlyBird from './EarlyBird'
import SendSOL from './SendSOL';
import { Connection, PublicKey, clusterApiUrl, LAMPORTS_PER_SOL, Transaction, SystemProgram } from '@solana/web3.js';
import Swal from 'sweetalert2';

function Tokenomics() {
    const [copiedText, setCopiedText] = useState('');
    const [activeState, setActiveState] = useState(1);

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopiedText(text);
            setTimeout(() => {
                setCopiedText('');
            }, 2000);
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    const address = "2Cm6qW4FcvFr2fGXFqM19Qg3GHRgYgnXUSTXTYFX4ymP";
    const [walletAddress, setWalletAddress] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const [balance, setBalance] = useState('0.0000000');


    useEffect(() => {
        const checkIfWalletIsConnected = async () => {
            try {
                const { solana } = window;
                if (solana) {
                    const response = await solana.connect({ onlyIfTrusted: true });
                    setWalletAddress(response.publicKey.toString());
                    setIsConnected(true);
                    await getBalance(response.publicKey.toString());
                }
            } catch (error) {
                console.error('Error checking if wallet is connected:', error);
            }
        };

        window.addEventListener('load', checkIfWalletIsConnected);
        return () => window.removeEventListener('load', checkIfWalletIsConnected);
    }, []);

    const connectWallet = async () => {
        const { solana } = window;
        if (solana) {
            try {
                const response = await solana.connect();
                setWalletAddress(response.publicKey.toString());
                setIsConnected(true);
                await getBalance(response.publicKey.toString());
            } catch (error) {
                console.error('Error connecting to wallet:', error);
            }
        } else {
            Swal.fire('Warning', 'Wallet not found! Get a Phantom Wallet 👻', 'warning');
        }
    };

    const getBalance = async (walletAddress) => {
        try {
            const connection = new Connection(clusterApiUrl('mainnet-beta'), 'confirmed');
            const balance = await connection.getBalance(new PublicKey(walletAddress));
            setBalance((balance / LAMPORTS_PER_SOL).toFixed(7));
        } catch (error) {
            console.error('Error fetching balance:', error?.response);
        }
    };
    return (
        <section className="flex flex-col items-center pt-20 mt-10 w-full shadow-sm max-md:max-w-full radiantBg">
            <header className="flex gap-5 py-11 mt-7 max-w-full rounded-3xl w-[1272px] max-md:flex-wrap max-md:px-5">
                <h2 className="flex-auto text-7xl font-black text-yellow-100 max-md:max-w-full max-md:text-4xl">Tokenomics</h2>
                <div className="flex gap-3 self-start text-sm font-extrabold">
                    <div className={`justify-center items-start px-6 py-5 text-white rounded-2xl ${activeState === 1 ? 'bg-[#00015E]' : 'text-[#00015E] bg-[#2495ee99]'} max-md:px-5 cursor-pointer`} onClick={() => setActiveState(1)}>Early Bird</div>
                    <div className={`justify-center items-start px-6 py-5 text-white rounded-2xl ${activeState === 2 ? 'bg-[#00015E]' : 'text-[#00015E] bg-[#2495ee99]'} max-md:px-5 cursor-pointer`} onClick={() => setActiveState(2)}>Wallet</div>
                </div>
                {/* <div className="flex gap-3 self-start text-sm font-extrabold">
                    <div className="justify-center items-start px-7 py-5 whitespace-nowrap rounded-xl bg-white bg-opacity-30 text-blue-950 max-md:px-5">0.3%</div>
                    <Button className="justify-center items-start px-6 py-5 text-white rounded-2xl bg-[#00015E] max-md:px-5">Connect Wallet</Button>
                </div> */}
            </header>
            <div className="mt-2 w-full max-w-[1274px] max-md:max-w-full">
                <div className="flex gap-5 max-md:flex-col max-md:gap-0 justify-between">
                    <div className="flex flex-col w-[40%] max-md:ml-0 max-md:w-full">
                        <div className="px-5 md:px-0 flex flex-col grow mt-16 max-md:mt-10 max-md:max-w-full">
                            <section>
                                <h3 className="text-4xl font-black text-white max-md:max-w-full">Content:</h3>
                                <div className="flex gap-5 justify-between px-5 py-7 mt-5 text-white capitalize whitespace-nowrap rounded-3xl bg-[#00015E] max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                                    <h4 className="text-4xl font-extrabold">Symbol</h4>
                                    <p className="text-3xl font-medium">$TPDG</p>
                                </div>
                                {/* <div className="flex gap-5 justify-between px-5 py-7 mt-5 text-white capitalize whitespace-nowrap rounded-3xl bg-[#00015E] max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                                    <h4 className="text-4xl font-extrabold">Tax</h4>
                                    <p className="text-3xl font-medium">0/0</p>
                                </div>
                                <div className="flex gap-5 justify-between px-5 py-7 mt-5 text-white capitalize whitespace-nowrap rounded-3xl bg-[#00015E] max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                                    <h4 className="text-4xl font-extrabold">LP</h4>
                                    <p className="text-3xl font-medium">Burned Forever</p>
                                </div> */}
                                <div className="flex gap-5 justify-between px-5 py-7 mt-5 text-white capitalize whitespace-nowrap rounded-3xl bg-[#00015E] max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                                    <h4 className="text-4xl font-extrabold">Total Supply</h4>
                                    <p className="text-3xl font-medium">470,005,000</p>
                                </div>
                                <p className="text-xl text-[#FCE839] mt-2 font-semibold">* 400,000,000 for the number of Dog Owners & 5,000 for the rarity of Lancashire Heeler’s</p>
                                <div className="flex gap-5 justify-center px-5 py-7 mt-5 text-white capitalize whitespace-nowrap rounded-3xl bg-[#00015E] max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                                    <h4 className="text-4xl font-extrabold">ANTI-WHALE FEATURE</h4>
                                </div>
                            </section>
                        </div>
                    </div>

                    <div className="flex flex-col p-5 w-[50%] max-md:ml-0 max-md:w-full items-end">
                        {activeState === 2 ? <SendSOL walletAddress={walletAddress}
                            isConnected={isConnected}
                            balance={balance}
                            connectWallet={connectWallet}
                            getBalance={getBalance} /> :
                            <EarlyBird />}
                    </div>
                </div>
            </div>
            {/* <section className="flex gap-5 px-7 py-7 mt-7 max-w-full rounded-3xl bg-[#00015E] w-[1272px] max-md:flex-wrap max-md:px-5 items-center">
                <h4 className="flex-auto self-start mt-2.5 text-3xl font-extrabold text-white capitalize">Token Address</h4>
                <div className="flex md:gap-5 max-md:max-w-full items-center">
                    <p className="flex-auto self-start mt-3 text-3xl font-medium text-white capitalize">XXXXXXXXXXXXXXXXXXXX</p>
                    <img src={copy} alt="copy" />
                </div>
            </section> */}
            <div className="mx-5 md:mx-0 flex gap-5 px-7 py-7 mt-7 max-w-full rounded-3xl bg-[#00015E] w-[1272px] max-md:flex-wrap max-md:px-5 items-center">
                <h4 className="flex-auto self-start mt-2.5 text-3xl font-extrabold text-white capitalize">Send SOL</h4>
                <div className="md:flex md:gap-5 max-md:max-w-full items-center">
                    <p className="flex-auto self-start mt-3 text-3xl font-medium text-white capitalize" onClick={() => handleCopy(address)}>{address}</p>
                    <img
                        src={copy}
                        alt="copy"
                        className="pt-2 cursor-pointer"
                        onClick={() => handleCopy(address)}
                    />
                </div>
            </div>
            {copiedText && (
                <div className="fixed z-40 bottom-4 left-1/2 transform -translate-x-1/2 bg-green-500 text-white py-2 px-4 rounded">
                    Copied: {copiedText}
                </div>
            )}
        </section>
    )
}

export default Tokenomics