import React, { useRef } from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import PrivacyPolicy from '../Components/PrivacyPolicy';
import { useNavigate } from 'react-router-dom';

const Privacy = () => {
    const homeRef = useRef(null);
    const aboutRef = useRef(null);
    const tokenomicsRef = useRef(null);
    const communityRef = useRef(null);
    const merchRef = useRef(null);
    const contactRef = useRef(null);
    const navigate = useNavigate()

    const scrollToSection = (ref) => {
        navigate('/')
        // ref.current.scrollIntoView({ behavior: 'smooth' });
    };


    return (
        <div className="flex flex-col shadow-sm bg-[#00015E]">
            <Header
                isHome={false}
                scrollToSection={scrollToSection}
                homeRef={homeRef}
                aboutRef={aboutRef}
                tokenomicsRef={tokenomicsRef}
                communityRef={communityRef}
                merchRef={merchRef}
                contactRef={contactRef}
            />

            <PrivacyPolicy />

            <Footer />

        </div>

    );
};

export default Privacy;
