import { useEffect, useState } from "react";

const ProgressBar = ({ initialTime }) => {
    const [progressTime, setProgressTime] = useState(initialTime);
    const [elapsedSeconds, setElapsedSeconds] = useState(0);

    useEffect(() => {
        if (progressTime <= 0) return;

        const intervalId = setInterval(() => {
            setProgressTime((prevSeconds) => {
                const newSeconds = prevSeconds - 1;
                if (newSeconds >= 0) {
                    setElapsedSeconds(elapsedSeconds + 1);
                }
                return newSeconds;
            });
        }, 1000);

        return () => clearInterval(intervalId);
    }, [progressTime, elapsedSeconds]);

    const percentage = (elapsedSeconds / initialTime) * 100 * 100 * 10;

    return (
        <div className="relative max-w-lg mt-4 h-14 flex bg-blue-500 justify-start items-center overflow-hidden rounded-md md:w-11/12">
            <p className="absolute w-full text-[#e9d3d3] leading-9 text-3xl font-bold text-center z-20">
                TILL NEXT PRICE INCREASE
            </p>
            <div
                className="absolute bg-gradient-to-r from-purple-700 via-blue-500 to-teal-400 z-10 h-full transition-all duration-500"
                style={{ width: percentage < 100 ? `${percentage}%` : '100%' }}
            />
            <div className="bg-black h-full w-full" />
        </div>
    );
};

export default ProgressBar;
