import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from '../pages';
import Privacy from '../pages/Privacy';
import TermsCondition from '../pages/TermsCondition';
// import Wallet from '../Components/Wallet';


const RouterDOM = () => {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<HomePage />} />
				<Route path="/privacy_policy" element={<Privacy />} />
				<Route path="/terms_and_conditions" element={<TermsCondition />} />
				{/* <Route path="/wallet" component={Wallet} /> */}
			</Routes>
		</Router>
	);
}
export default RouterDOM;
