import React from 'react';
import Slider from 'react-slick';
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Banner = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        pauseOnHover: true
    };

    return (
        <footer className="z-5 self-stretch px-20 py-9 mb-0 bg-white max-md:px-5 max-md:mt-10 max-md:mb-2.5 max-md:max-w-full">
            <Slider {...settings}>
                <div className="flex flex-col">
                    <h4 className="flex grow gap-4 text-3xl font-extrabold capitalize text-blue-950 max-md:mt-10">
                        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/2f348244931d8fff36e996330be49d06d9200e7f60dbb4cc3a3db819e23245cb?apiKey=9db28024b9de495c90b2cacb6d403710&" alt="AP News" className="shrink-0 aspect-square w-[92px]" />
                        <span className="flex-auto my-auto hidden md:flex">AP News</span>
                    </h4>
                </div>
                <div className="flex flex-col">
                    <h4 className="flex grow gap-4 text-3xl font-extrabold capitalize text-blue-950 max-md:mt-10">
                        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/c4328aec707bd2b5608dc8802efd131237ff0b929ff83d4132e84470d7bfd511?apiKey=9db28024b9de495c90b2cacb6d403710&" alt="Google News" className="shrink-0 aspect-square w-[92px]" />
                        <span className="flex-auto my-auto hidden md:flex">Google News</span>
                    </h4>
                </div>
                <div className="flex flex-col">
                    <h4 className="flex grow gap-4 text-3xl font-extrabold capitalize text-blue-950 max-md:flex-wrap max-md:mt-10">
                        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/2c7df554b1af97041d2305715fa4c69305b0ebaea9bc21f22430a1d390759fc1?apiKey=9db28024b9de495c90b2cacb6d403710&" alt="Yahoo Finance" className="shrink-0 aspect-square w-[92px]" />
                        <span className="flex-auto my-auto hidden md:flex">Yahoo Finance</span>
                    </h4>
                </div>
                <div className="flex flex-col">
                    <h4 className="flex grow gap-4 text-3xl font-extrabold capitalize text-blue-950 max-md:flex-wrap max-md:mt-10">
                        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/2f348244931d8fff36e996330be49d06d9200e7f60dbb4cc3a3db819e23245cb?apiKey=9db28024b9de495c90b2cacb6d403710&" alt="AP News" className="shrink-0 aspect-square w-[92px]" />
                        <span className="flex-auto my-auto hidden md:flex">AP News</span>
                    </h4>
                </div>
                <div className="flex flex-col">
                    <h4 className="flex grow gap-4 text-3xl font-extrabold capitalize text-blue-950 max-md:flex-wrap max-md:mt-10">
                        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/2c7df554b1af97041d2305715fa4c69305b0ebaea9bc21f22430a1d390759fc1?apiKey=9db28024b9de495c90b2cacb6d403710&" alt="Yahoo Finance" className="shrink-0 aspect-square w-[92px]" />
                        <span className="flex-auto my-auto hidden md:flex">Yahoo Finance</span>
                    </h4>
                </div>
            </Slider>
        </footer>
    );
};


export default Banner