import React from 'react'
import { Button } from './MainBanner'

function PoweredBy() {
    return (
        <>
            <section className="flex gap-2 items-end self-center mt-8 font-black text-yellow-100 capitalize">
                <div>
                    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/18d641f59352d96166c481b0b7a8a578c27603d8ddce5d5a448a7d26f4ef61d9?apiKey=9db28024b9de495c90b2cacb6d403710&" alt="" className="shrink-0 mt-4 aspect-[1.03] w-[74px]" />
                </div>
                <div className="flex flex-col px-5">
                    <p className="self-center text-xl">Powered by</p>
                    <p className="mt-3 text-6xl max-md:text-4xl">SOLANA</p>
                </div>
            </section>
            <section className="self-center mt-5 w-full max-w-[1321px] max-md:max-w-full">
                <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                    <div className="px-5 md:px-0 flex flex-col w-[38%] max-md:ml-0 max-md:w-full">
                        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/7273da3879ff9bf178514d8db70b6388696efda027b8575fb3d0c40249b294c3?apiKey=9db28024b9de495c90b2cacb6d403710&" alt="" className="grow w-full aspect-[0.8] max-md:mt-10 max-md:max-w-full" />
                    </div>
                    <article className="px-5 md:px-0 flex flex-col ml-5 w-[62%] max-md:ml-0 max-md:w-full">
                        <section className="flex grow gap-5 pt-16 pr-3 pb-6 pl-12 w-full border-white border-solid bg-[#00015E] border-[5px] rounded-[64px] max-md:flex-wrap max-md:pl-5 max-md:mt-10">
                            <div className="flex flex-col grow shrink-0 basis-0 w-fit max-md:max-w-full">
                                <h3 className="text-3xl md:text-6xl font-black text-yellow-100 max-md:max-w-full">About Top Dog to <br /> Rule Them All</h3>
                                <p className="mt-9 text-3xl font-medium text-white max-md:max-w-full h-[20rem] overflow-y-auto custom-scrollbar">
                                    In the vibrant world of cryptocurrencies, a new contender emerges as the leader of the pack—Top Dog to Rule Them All (TPDG)! Drawing inspiration from the loyal, playful, and ever-alert Lancashire Heeler, TPDG is here to bring unparalleled charm and tenacity to the crypto market.
                                    <br />
                                    <br />
                                    Much like the Lancashire Heeler, recently recognized by the American Kennel Club and the prestigious Westminster Kennel Club show, TPDG is poised to take the spotlight in the latest bull market. Just as this rare herding breed is celebrated for its unique qualities, TPDG is designed to be a reliable companion in your digital wallet, ever-vigilant and ready to alert you to new opportunities while safeguarding your investments.
                                </p>
                            </div>
                        </section>
                    </article>


                </div>
                <div className="text-center mt-[100px] mb-12 text-2xl text-indigo-950 max-md:flex-wrap">
                    {/* <Button className="py-4 px-8 BuyTPDG">Buy $TPDG</Button> */}
                    <Button className="py-4 px-8 BuyTPDG">Learn More</Button>
                </div>
            </section>
        </>
    )
}

export default PoweredBy