import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

function Community() {
    const [countryCode, setCountryCode] = useState('+61');
    const [phoneNumber, setPhoneNumber] = useState('');

    const handleCountryCodeChange = (e) => {
        const value = e.target.value;
        if (value.length <= 3) {
            setCountryCode(value);
        }
    };

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value);
    };

    const navigate = useNavigate()

    return (
        <section className="self-center mt-40 w-full max-w-[1271px] max-md:mt-10 max-md:max-w-full mb-12">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0 justify-between">
                <div className="flex flex-col w-[50%] max-md:ml-0 max-md:w-full">
                    <article
                        // style={{ lineHeight: '3rem' }}
                        className="justify-center px-12 py-16 w-full text-3xl font-medium text-white border-white border-solid bg-[#00015E] border-[5px] rounded-[64px] max-md:px-5 max-md:mt-10 max-md:max-w-full">
                        It seems as if people are saying that "Cats are going to be a huge narrative this cycle."
                        <p className='h-[20rem] overflow-y-auto custom-scrollbar '>
                        <br /> <span className='text-[#FCE839]'>FUCK THE CATS!</span> I'm Top DOG—the newest dog on the block. Do you know that there are 470 million dog owners and only 370 million cat owners?
                        <br /> <br />  If I were you, I’d do some research:
                        <ul className="list-star list-disc pl-5 space-y-2 text-xl font-semibold py-3">
                            <li>Top Dog will perform this cycle.</li>
                            <li>Top Dog thinks we have another year of massive gains ahead of us. </li>
                            <li>The cats will eat, but good luck getting into the top 100 coins and into the billions.</li>
                        </ul>
                        </p>
                       
                    </article>
                </div>
                <div className="flex flex-col ml-5 w-[40%] max-md:ml-0 max-md:w-full">
                    <aside className="flex flex-col justify-center grow mt-3.5 font-black max-md:mt-10 max-md:max-w-full">
                        <button onClick={() => navigate('https://x.com/tpdgwtf')} className="justify-center py-9 pr-20 pl-20 text-3xl rounded-3xl shadow-sm text-indigo-950 max-md:px-8 max-md:max-w-full BuyTPDG">Join Our Community</button>
                        {/* <section className="flex flex-col pl-4 px-4 md:px-0 mt-16 text-3xl text-white max-md:mt-10 max-md:max-w-full">
                            <h4 className="text-3xl text-[#FCFDB6] max-md:max-w-full">Join Our Discussion</h4>
                            <form className="flex flex-col gap-6">
                                <label htmlFor="email" className="sr-only">Email</label>
                                <input
                                    className="justify-center items-start px-4 py-5 mt-7 bg-transparent text-white font-semibold whitespace-nowrap rounded-3xl border border-white border-solid max-md:pr-5 max-md:max-w-full"
                                    type="email"
                                    id="email"
                                    placeholder="Email"
                                    aria-label="Email"
                                />
                                <div className="flex gap-5 items-center px-4 py-px mt-7 font-semibold whitespace-nowrap rounded-3xl border border-white border-solid">
                                    <label htmlFor="mobile" className="sr-only">Mobile</label>
                                    <input
                                        style={{ maxWidth: 55 }}
                                        type="tel"
                                        className="bg-transparent border-none outline-none px-0"
                                        value={countryCode}
                                        onChange={handleCountryCodeChange}
                                        aria-label="Country Code"
                                    />
                                    <span className="shrink-0 self-start w-px bg-white border border-white border-solid h-[72px]" />
                                    <input
                                        className="flex-auto self-stretch my-auto bg-transparent py-0 outline-none"
                                        type="tel"
                                        id="mobile"
                                        placeholder="Mobile"
                                        aria-label="Mobile"
                                        value={phoneNumber}
                                        onChange={handlePhoneNumberChange}
                                    />
                                </div>
                                <button type="button" className="join justify-center items-center px-16 py-6 mt-7 text-4xl whitespace-nowrap rounded-3xl border border-white border-solid shadow-sm max-md:px-5 max-md:max-w-full">
                                    JOIN
                                </button>
                            </form>
                        </section> */}
                    </aside>
                </div>
            </div>
        </section>
    )
}

export default Community