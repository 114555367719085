import React, { useRef, useState } from 'react';
import menu from '../assets/imgs/menu.svg';
import logo from '../assets/imgs/logo.svg';
import { Link, useNavigate } from 'react-router-dom';

function Header({ isHome = true, homeRef, aboutRef, tokenomicsRef, communityRef, merchRef, contactRef, scrollToSection }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate()
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    function scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    return (
        <header className="py-10 pr-20 pl-7 w-full bg-[#00015E] max-md:px-5 max-md:max-w-full md:fixed z-10" ref={homeRef} id='home'>
            <div className="flex justify-between items-center max-md:flex-col">
                <div className="flex items-center w-[34%] max-md:w-full">
                    <img
                        loading="lazy"
                        src={menu}
                        alt="Menu"
                        className="shrink-0 my-auto aspect-[2.27] w-[34px] cursor-pointer md:hidden"
                        onClick={toggleMenu}
                    />
                    {/* <img
                        loading="lazy"
                        src={menu}
                        alt="Menu"
                        className="shrink-0 my-auto aspect-[2.27] w-[34px] cursor-pointer hidden md:block mr-5"
                    // onClick={toggleMenu}
                    /> */}
                    <h1 className="flex justify-center md:justify-start w-full">
                        <p className='text-3xl md:text-4xl text-[#FCFDB6] font-medium' style={{ fontFamily: 'fantasy' }} onClick={() => navigate('/')}>
                            Top Dog To Rule Them All
                        </p>
                        {/* <img src={logo} alt="logo" style={{ width: 300 }} /> */}
                    </h1>
                </div>
                <nav className={`hidden md:flex flex-col ml-5 w-[66%] max-md:w-full ${isMenuOpen ? 'block' : 'hidden'} md:flex md:items-center`}>
                    <div className="flex items-center justify-end gap-5 self-stretch my-auto text-2xl font-black text-white max-md:flex-wrap max-md:mt-10">
                        {/* {isHome &&
                            <> */}
                                <Link onClick={() => isHome ? scrollToTop() : navigate('/')} className="grow pointer">Home</Link>
                                <Link onClick={() => scrollToSection(aboutRef)} className="flex-auto pointer">About</Link>
                                <Link onClick={() => scrollToSection(tokenomicsRef)} className="flex-auto pointer">Tokenomics</Link>
                                <Link to='https://t.me/topdog' className="flex-auto pointer">Community</Link>
                                <Link to={'https://shop.tpdg.wtf'} className="flex-auto pointer">TPDG Merch</Link>
                                <Link onClick={() => scrollToSection(contactRef)} className="flex-auto pointer">Contact</Link>
                            {/* </>
                        } */}
                        <Link onClick={() => scrollToSection(tokenomicsRef)} className="flex-auto pointer BuyTPDG md:max-w-[300px]">Connect Wallet</Link>
                    </div>
                </nav>
                <nav className={`flex md:hidden flex-col ml-5 w-[66%] max-md:w-full ${isMenuOpen ? 'block' : 'hidden'} md:flex md:items-center`}>
                    <div className="flex flex-wrap gap-5 self-stretch my-auto text-2xl font-black text-white max-md:mt-10">
                        <Link onClick={() => scrollToTop()} className="w-full text-center max-md:grow">Home</Link>
                        <Link onClick={() => scrollToSection(aboutRef)} className="w-full text-center max-md:grow">About</Link>
                        <Link onClick={() => scrollToSection(tokenomicsRef)} className="w-full text-center max-md:grow">Tokenomics</Link>
                        <Link to='https://t.me/topdog' className="w-full text-center max-md:grow">Community</Link>
                        <Link to={'https://shop.tpdg.wtf'} className="w-full text-center max-md:grow">TPDG Merch</Link>
                        <Link onClick={() => scrollToSection(contactRef)} className="w-full text-center max-md:grow ">Contact</Link>
                    </div>
                </nav>
            </div>
        </header>
    );
}

export default Header;
