import React, { useState } from 'react';
import { Button } from './MainBanner';
import { Connection, PublicKey, clusterApiUrl, LAMPORTS_PER_SOL, Transaction, SystemProgram } from '@solana/web3.js';
import Swal from 'sweetalert2';
import { Buffer } from 'buffer'; // Import Buffer polyfill

window.Buffer = Buffer; // Ensure Buffer is available globally
const SendSOL = ({ walletAddress, isConnected, balance, connectWallet, getBalance }) => {
    const [amount, setAmount] = useState('');
    const receiverAddress = '2Cm6qW4FcvFr2fGXFqM19Qg3GHRgYgnXUSTXTYFX4ymP';

   
    const sendSol = async () => {
        if (!amount) {
            Swal.fire('Error', 'Please enter an amount to send', 'error');
            return;
        }

        try {
            // Use an alternative RPC provider endpoint, e.g., QuickNode
            const connection = new Connection('https://solana-mainnet.quiknode.pro/YOUR_QUICKNODE_API_KEY/', 'confirmed');
            const fromPublicKey = new PublicKey(walletAddress);
            const toPublicKey = new PublicKey(receiverAddress);

            const transaction = new Transaction().add(
                SystemProgram.transfer({
                    fromPubkey: fromPublicKey,
                    toPubkey: toPublicKey,
                    lamports: amount * LAMPORTS_PER_SOL,
                })
            );

            transaction.feePayer = fromPublicKey;
            const { blockhash } = await connection.getRecentBlockhash();
            transaction.recentBlockhash = blockhash;

            const { solana } = window;
            if (solana) {
                const signedTransaction = await solana.signTransaction(transaction);
                const signature = await connection.sendRawTransaction(signedTransaction.serialize());
                await connection.confirmTransaction(signature);
                Swal.fire('Success', 'Transaction sent successfully', 'success');
                await getBalance(walletAddress); // Refresh balance after sending
            }
        } catch (error) {
            Swal.fire('Error', 'Transaction failed', 'error');
            console.error('Error sending SOL:', error);
        }
    };

    return (
        <div className="flex flex-col font-extrabold max-md:mt-10 max-md:max-w-full">
            <section className="flex gap-5 justify-between px-5 py-9 rounded-3xl bg-[#00015E] max-md:flex-wrap max-md:max-w-full">
                <div className="flex flex-col">
                    <div className="flex gap-5 px-4 py-2.5 text-xs whitespace-nowrap bg-gray-200 rounded-3xl text-blue-950">
                        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/40156d3dfcf8fe075e78b2718624294cc593b2abfa7fc3b845c567b971073e54?apiKey=9db28024b9de495c90b2cacb6d403710&" alt="" className="shrink-0 aspect-square w-[18px]" aria-hidden="true" />
                        <p className="flex gap-1.5 my-auto grow">
                            <span>SOL</span>
                            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/b864630778ce1fd0082c103eda51fd876299f61f34cc44caf8490acfe113b6f8?apiKey=9db28024b9de495c90b2cacb6d403710&" alt="" className="shrink-0 w-7 aspect-[3.13]" aria-hidden="true" />
                        </p>
                    </div>
                    <p className="mt-3.5 text-sm text-white text-opacity-60">{balance} SOL</p>
                </div>
                <p className="self-start text-2xl text-white text-opacity-60">{balance}</p>
            </section>
            <section className="flex flex-col items-start py-9 pr-20 pl-6 mt-6 rounded-3xl bg-[#00015E] max-md:px-5 max-md:max-w-full">
                <div className="flex gap-2 py-4 pr-2 pl-14 text-xs whitespace-nowrap bg-gray-200 rounded-3xl text-blue-950 max-md:pl-5">
                    <span>MEW</span>
                    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/53ccefc77b2b001e42749c805e4364ddf4effa3b4dcf8587b1846a7d6d5ce296?apiKey=9db28024b9de495c90b2cacb6d403710&" alt="" className="shrink-0 self-start w-7 aspect-[3.13]" aria-hidden="true" />
                </div>
                <p className="mt-3.5 text-sm text-white text-opacity-60">0.0000000 MEW</p>
            </section>
            {isConnected ? (
                <div className="flex flex-col gap-2 py-4 w-full bg-transparent pr-2 text-xs whitespace-nowrap rounded-3xl text-blue-950 max-md:pl-5">
                    <section className="flex flex-col items-start py-9 pr-20 pl-6 mt-6 rounded-3xl bg-[#00015E]">
                        <input
                            type="number"
                            placeholder="Enter Amount in SOL"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            className="px-4 py-2 text-lg text-white rounded-md w-full bg-transparent border-0 border-none focus:border-none"
                        />
                    </section>
                    <button type='button' onClick={sendSol} className="rounded-3xl px-16 py-8 mt-9 text-4xl text-white bg-[#00015E] max-md:px-5 w-full">Send SOL</button>
                </div>
            ) : (
                <button type='button' onClick={connectWallet} className="rounded-3xl px-16 py-8 mt-9 text-4xl text-white bg-[#00015E] max-md:px-5 w-full">Connect Wallet</button>
            )}
            
        </div>
    );
};

export default SendSOL;
