
const PrivacyPolicy = () => {

    return (
        <div className="bg-[#00015E] text-white p-8 md:mt-[160px] border-t-[#fcfdb6] border-b-[#fcfdb6] border-t-2 border-b-2">
            <h1 className="text-4xl font-bold mb-6 text-center underline decoration-pink-500">Privacy Policy for Top Dog to Rule Them All (TPDG)</h1>
            <p className="text-sm text-center italic mb-10">Last updated: May 30, 2024</p>
            <div className=" flex flex-wrap">
                <section className="mb-8 pe-4  md:w-6/12">
                    <h2 className="text-2xl font-semibold mb-2 border-b border-gray-600 pb-2">Introduction</h2>
                    <p className="mb-4">
                        Welcome to Top Dog to Rule Them All (TPDG). This Privacy Policy is an integral part of our Terms and Conditions.
                        The definitions used in this Privacy Policy have the same meanings as in the Terms unless otherwise specified.
                    </p>
                    <p>
                        Our Privacy Policy outlines how TPDG and TPDG Operators collect, use, process, disclose, share, transfer, and protect personal
                        information obtained through TPDG and its partners. When we ask for personal information, it is either because it is required
                        by law or relevant to our specified purposes.
                        <br />
                        <br />
                        By visiting the TPDG website, whether or not you register or log in, you acknowledge, understand, and consent
                        to the terms described in this Privacy Policy. We will not use your personal information for any purposes not
                        covered in this Privacy Policy, the Cookies Policy, or the Terms without notifying you in advance and obtaining
                        your consent.

                    </p>
                </section>

                <section className="mb-8 pe-4  md:w-6/12">
                    <h2 className="text-2xl font-semibold mb-2 border-b border-gray-600 pb-2">1. Types of Data We Collect</h2>
                    <p className="mb-5">The types of personal data we collect, either directly from you or from third parties, depend on the circumstances of collection and the nature of the service or transaction. The data may include, but is not limited to:</p>
                    <ul className="list-disc list-inside ml-4">
                        <li className="mb-2">Personal information that identifies an individual such as name, date of birth, and identification numbers.</li>
                        <li className="mb-2">Contact information including address, phone number, email address, and Ethereum wallet address.</li>
                        <li className="mb-2">Technical information such as IP address for API services and logins.</li>
                        <li className="mb-2">Statistical data such as website visits.</li>
                    </ul>
                </section>

                {/* Add other sections similarly */}
                <section className="mb-8 pe-4  md:w-6/12">
                    <h2 className="text-2xl font-semibold mb-2 border-b border-gray-600 pb-2">2. How Do We Collect Personal Data?</h2>
                    <p className="mb-5">This Privacy Policy encompasses the collection of personal data in the following ways:</p>
                    <ul className="list-disc list-inside ml-4">
                        <li className="mb-2">When you interact with our products and services</li>
                        <li className="mb-2">When you create an account with us.</li>
                        <li className="mb-2">Under any other contractual agreement or arrangement.</li>
                        <li className="mb-2">Communications via telephone, letter, fax, and email.</li>
                        <li className="mb-2">Website visits</li>
                        <li className="mb-2">In-person interactions</li>
                        <li>Information obtained from third parties, including social media platforms and our support helpdesk.</li>
                        <li>Potential extrapolation of information through data processing.</li>
                    </ul>
                </section>

                <section className="mb-8 pe-4 md:w-6/12">
                    <h2 className="text-2xl font-semibold mb-2 border-b border-gray-600 pb-2">3. What Other Data Do We Collect?</h2>
                    <p className="mb-5">We collect your personal data from our website and services through the following means:</p>
                    <ul className="list-disc list-inside ml-4">
                        <li className="mb-2"><strong>IP address:</strong> We use your IP address to diagnose server problems and administer our website. IP addresses are not linked to personally identifiable information.</li>
                        <li className="mb-2"><strong>Cookies:</strong> We utilize cookies on some pages to store preferences and record session information, ensuring a more personalized user experience. Please refer to our Cookie Policy for detailed information on how we use cookies.</li>
                        <li className="mb-2"><strong>User feedback form:</strong> Our feedback form collects contact information (e.g., name and email address) to respond to comments and provide relevant information about our company.</li>
                        <li><strong>Site tracking:</strong> We employ third-party service providers to help us understand site usage. These service providers place cookies on your computer's hard drive and receive selected information, such as navigation patterns, page browsing, and general transaction data.</li>
                    </ul>
                </section>

                <section className="mb-8 pe-4 md:w-6/12">
                    <h2 className="text-2xl font-semibold mb-2 border-b border-gray-600 pb-2">4. What Do We Use Your Personal Data For?</h2>
                    <p className="mb-5">We use your personal data for the following purposes:</p>
                    <ul className="list-disc list-inside ml-4">
                        <li className="mb-2">Providing and performing our services to you.</li>
                        <li className="mb-2">Ensuring the safety and well-being of yourself and other customers.</li>
                        <li className="mb-2">Investigating and responding to your claims and inquiries.</li>
                        <li className="mb-2">Conducting business development activities such as statistical and marketing analysis, system testing, maintenance and development, and customer surveys.</li>
                        <li className="mb-2">Complying with legal and regulatory requirements.</li>
                        <li className="mb-2">Communicating offers, products, services, and information on products and activities.</li>
                        <li>Marketing and cross-marketing activities, and communication related to products and services offered by us, our service partners, and appointed agents.</li>
                    </ul>
                </section>

                <section className="mb-8 pe-4 md:w-6/12">
                    <h2 className="text-2xl font-semibold mb-2 border-b border-gray-600 pb-2">5. Accessing / Correcting / Updating Your Personal Data</h2>
                    <p className="mb-5">You have the following rights:</p>
                    <ul className="list-disc list-inside ml-4">
                        <li className="mb-2"><strong>Right of Access:</strong> You can access or obtain copies of your personal information.</li>
                        <li className="mb-2"><strong>Right to Rectification:</strong> You can correct inaccurate personal information and complete incomplete information.</li>
                        <li className="mb-2"><strong>Right to Erasure:</strong> You can request the erasure of your personal data under certain circumstances.</li>
                        <li className="mb-2"><strong>Right to Restriction of Processing:</strong> You can request the restriction of processing of your personal information under certain circumstances.</li>
                        <li className="mb-2"><strong>Right to Object:</strong> You can object to the processing of your personal information under certain circumstances.</li>
                        <li><strong>Right to Data Portability:</strong> You can request the transmission of your personal information to another body or directly to you under certain circumstances.</li>
                    </ul>
                </section>

                <section className="mb-8 pe-4 md:w-6/12">
                    <h2 className="text-2xl font-semibold mb-2 border-b border-gray-600 pb-2">6. Withdrawing Consent</h2>
                    <p className="mb-5">
                        Processing your personal data for the core purposes is necessary for us to provide our services. If you do not consent to the processing of your personal data for ancillary purposes, you may not receive updates about our services and products. You can stop receiving promotional activities by unsubscribing from mailing lists, editing your account settings, or sending a request through tpdg@tpdg.wtf.
                    </p>
                </section>

                <section className="mb-8 pe-4 md:w-6/12">
                    <h2 className="text-2xl font-semibold mb-2 border-b border-gray-600 pb-2">7. Disclosure of Your Personal Data</h2>
                    <p className="mb-5">
                        We do not trade or sell your personal data to third parties. We may disclose or transfer your personal data to the following third parties for the purposes stated above:
                    </p>
                    <ul className="list-disc list-inside ml-4">
                        <li className="mb-2">Data warehouses.</li>
                        <li className="mb-2">IT service providers.</li>
                        <li className="mb-2">Data analytics and/or marketing agencies.</li>
                        <li className="mb-2">Legal bodies as permitted or required by law.</li>
                        <li className="mb-2">Regulatory authorities applicable to you.</li>
                        <li className="mb-2">Safety and security personnel.</li>
                    </ul>
                </section>

            </div>
        </div>
    );
};

export default PrivacyPolicy;
