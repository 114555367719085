import React from 'react';

const TermsAndConditions = () => {
    return (
        <div className="bg-[#00015E] text-white p-8 md:mt-[160px] border-t-[#fcfdb6] border-b-[#fcfdb6] border-t-2 border-b-2 flex flex-col gap-3">
            <h1 className="text-2xl font-bold mb-4">Terms and Conditions for Top Dog to Rule Them All (TPDG)</h1>
            <p className="mb-4">Last updated: May 10, 2024</p>

            <h2 className="text-xl font-semibold mt-4">Interpretation and Definitions</h2>

            <h3 className="text-lg font-semibold mt-2">Interpretation</h3>
            <p className="mb-2">
                The words with capitalized initial letters have specific meanings defined under these conditions. These definitions apply equally whether they appear in singular or plural form.
            </p>

            <h3 className="text-lg font-semibold mt-2">Definitions</h3>
            <ul className="list-disc list-inside mb-4">
                <li><strong>Affiliate:</strong> An entity that controls, is controlled by, or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote for election of directors or other managing authority.</li>
                <li><strong>Country:</strong> Refers to the United States.</li>
                <li><strong>Device:</strong> Any device that can access the Service, such as a computer, cellphone, or digital tablet.</li>
                <li><strong>Service:</strong> Refers to the Website.</li>
                <li><strong>Terms and Conditions (Terms):</strong> These Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.</li>
                <li><strong>Third-party Social Media Service:</strong> Any services or content provided by a third-party that may be displayed, included, or made available by the Service.</li>
                <li><strong>Website:</strong> Refers to Top Dog to Rule Them All, accessible from tpdg@tpdg.wtf.</li>
                <li><strong>You:</strong> The individual accessing or using the Service, or the company or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
            </ul>

            <h2 className="text-xl font-semibold mt-4">Acknowledgment</h2>
            <p className="mb-2">
                These Terms and Conditions govern the use of this Service and constitute the agreement between You and the Company. They set out the rights and obligations of all users regarding the use of the Service. By accessing or using the Service, You agree to be bound by these Terms. If You disagree with any part of the Terms, You may not access the Service.
            </p>

            <h2 className="text-xl font-semibold mt-4">Eligibility</h2>
            <p className="mb-2">
                You must be over the age of 18 to use this Service. The Company does not permit those under 18 to use the Service.
            </p>

            <h2 className="text-xl font-semibold mt-4">Privacy Policy</h2>
            <p className="mb-2">
                Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Please read Our Privacy Policy carefully before using Our Service.
            </p>

            <h2 className="text-xl font-semibold mt-4">Links to Other Websites</h2>
            <p className="mb-2">
                Our Service may contain links to third-party websites or services not owned or controlled by the Company. The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services.
            </p>

            <h2 className="text-xl font-semibold mt-4">Termination</h2>
            <p className="mb-2">
                We may terminate or suspend Your access immediately, without prior notice or liability, for any reason, including if You breach these Terms. Upon termination, Your right to use the Service will cease immediately.
            </p>

            <h2 className="text-xl font-semibold mt-4">Limitation of Liability</h2>
            <p className="mb-2">
                To the maximum extent permitted by law, the Company shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) Your use or inability to use the Service; (ii) any unauthorized access or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the Service; (iv) any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Service by any third party; (v) any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Service; and/or (vi) any other matters relating to the Service.
            </p>

            <h2 className="text-xl font-semibold mt-4">"AS IS" and "AS AVAILABLE" Disclaimer</h2>
            <p className="mb-2">
                The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Company disclaims all warranties of any kind, whether express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, non-infringement, or course of performance.
            </p>

            <h2 className="text-xl font-semibold mt-4">Governing Law</h2>
            <p className="mb-2">
                These Terms shall be governed and construed in accordance with the laws of the United States, without regard to its conflict of law provisions.
            </p>

            <h2 className="text-xl font-semibold mt-4">Disputes Resolution</h2>
            <p className="mb-2">
                If You have any concerns or disputes about the Service, You agree to first try to resolve the dispute informally by contacting the Company.
            </p>

            <h2 className="text-xl font-semibold mt-4">Severability and Waiver</h2>
            <p className="mb-2">
                If any provision of these Terms is held to be unenforceable or invalid, such provision will be modified and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law, and the remaining provisions will continue in full force and effect.
            </p>

            <h2 className="text-xl font-semibold mt-4">Changes to These Terms and Conditions</h2>
            <p className="mb-2">
                We reserve the right to modify or replace these Terms at any time. If a revision is material, we will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms.
            </p>

            <h2 className="text-xl font-semibold mt-4">Contact Us</h2>
            <p className="mb-2">
                If you have any questions about these Terms and Conditions, You can contact us:
            </p>
            <ul className="list-disc list-inside mb-4">
                <li>By email: tpdg@tpdg.wtf</li>
            </ul>
        </div>

    );
}

export default TermsAndConditions;
