import React from 'react';
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';
import ProgressBar from './ProgressBar';
import moment from 'moment';
import InfoList from './InfoList';
import { Button } from './MainBanner';

function EarlyBird() {
    const futureDate = "2024-05-30T12:20:00";
    const diffInMilliseconds = 500000//moment(futureDate).diff(moment());

    return (
        <div className="w-full text-white  flex justify-center items-center" style={{ borderRadius: '2rem', maxWidth: 550 }}>
            {/* LEFT */}
            <div className="w-full bg-[#00015E] p-8 rounded-lg" style={{ borderRadius: '2rem' }}>
                <h1 className="text-3xl font-bold tracking-tight mb-2">
                    PRESALE IS <span className="text-[#FCE839]">NOW LIVE!!</span>
                </h1>
                <div className="my-4">
                    <div className="border-[#FCE839] w-full md:max-w-xl overflow-x-auto">
                        <FlipClockCountdown
                            className="border-bg-[#FCE839]"
                            to={new Date().getTime() + diffInMilliseconds}

                        />
                    </div>
                    <ProgressBar initialTime={diffInMilliseconds} />
                </div>
                <p className='text-white text-xl font-semibold'>
                    HURRY <span className='text-[#FCE839]'>TO BUY YOUR $TPDG</span> BEFORE NEXT PRICE INCREASE
                    More Exclusive Presale Features Set To Go Live Wednesday 5/22 At 12pm UTC
                </p>
                <p>
                    <ul className="list-disc pl-5 space-y-2 text-xl font-semibold py-3">
                        <li>You can also send SOL to our address</li>
                        <li>Bonus airdrop countdown for the first 250</li>
                        <li>Follow our socials for announcements</li>
                    </ul>
                </p>
                {/* <h1 className="text-3xl font-bold tracking-tight my-2">
                    EARLY BIRD <span className="text-[#FCE839]">ACCESS</span>
                </h1> */}
                {/* Timer */}
                <Button className="mt-5 py-4 px-8 BuyTPDG font-semibold">Buy $TPDG</Button>
               


                {/* Send SOL */}
                {/* <InfoList /> */}
            </div>
        </div >
    );
}

export default EarlyBird;
