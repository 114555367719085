import React, { useRef } from "react";
import Banner from "../Components/Banner";
import MainBanner from "../Components/MainBanner";
import SocialLinks from "../Components/SocialLinks";
import PoweredBy from "../Components/PoweredBy";
import Tokenomics from "../Components/Tokenomics";
import Community from "../Components/Community";
import Contact from "../Components/Contact";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import WalletConnect from "../Components/WalletConnect";

const HomePage = () => {
	const homeRef = useRef(null);
	const aboutRef = useRef(null);
	const tokenomicsRef = useRef(null);
	const communityRef = useRef(null);
	const merchRef = useRef(null);
	const contactRef = useRef(null);

	const scrollToSection = (ref) => {
		ref.current.scrollIntoView({ behavior: 'smooth' });
	};

	return (
		<div className="flex flex-col shadow-sm bg-[#00015E]">
			{/* <WalletConnect /> */}
			{/* Header */}
			<Header
				scrollToSection={scrollToSection}
				homeRef={homeRef}
				aboutRef={aboutRef}
				tokenomicsRef={tokenomicsRef}
				communityRef={communityRef}
				merchRef={merchRef}
				contactRef={contactRef}
			/>
			{/* Main Banner */}
			<section className="md:mt-[100px]">
				<MainBanner />
			</section>

			{/* Social Links */}
			<div ref={aboutRef} id="about" className="flex flex-col items-center">
				<SocialLinks />
				<PoweredBy />
			</div>

			{/* Powered By */}
			<div ref={tokenomicsRef} id="tokenomics">
				<Tokenomics />
			</div>

			{/* Tokenomics */}
			{/* <div ref={communityRef} id="community">
			</div> */}

			{/* Banner */}
			<div ref={communityRef}  className="flex flex-col items-center pt-0 pb-10" id="community">
				<Community />
			</div>
			<Banner />

			{/* Join Community */}
			<div ref={contactRef} id="contact" className="flex flex-col items-center pb-10 pt-0">
				<Contact />
			</div>

			{/* Contact */}

			{/* Footer */}
			<Footer />
		</div>
	);
};

export default HomePage;
