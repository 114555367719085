import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <>
            <section className="pb-5 flex flex-col px-14 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
                <h2 className="text-2xl font-bold text-white max-md:max-w-full pt-10">Legal Disclaimer:</h2>
                <p className="self-start mt-3 text-xl font-medium text-slate-400 max-md:max-w-full">
                    $TPDG is a meme coin with no intrinsic value or expectation of financial return. $TPDG is for entertainment purposes only. When you purchase $TPDG,
                    you are agreeing that you have seen this disclaimer.
                </p>
                <p className='text-sm mt-5 text-white flex gap-2'>
                    <Link to='/privacy_policy' className='text-white' onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>Privacy Policy</Link>  »
                    <Link to='/terms_and_conditions' className='text-white' onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} > Terms & Conditions</Link>
                </p>
            </section >
            <p className='text-center text-xl text-white font-semibold  border-t-white py-3'>© Copyright 2024 ・ All rights reserved.</p>
        </>

    )
}

export default Footer