/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from 'react'
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import qrcode from '../assets/imgs/qrcode.png'

function Contact() {
    const [formData, setFormData] = useState({
        from_name: "",
        to_name: "Recipient",
        message: "",
        firstName: "",
        lastName: "",
        country: "",
        phoneNumber: "",
        email: ""
    });
    const clearForm = () => {
        setFormData({
            from_name: "",
            to_name: "Recipient",
            message: "",
            firstName: "",
            lastName: "",
            country: "",
            phoneNumber: "",
            email: ""
        })
    }
    const handleChange = (e) => {
        const { id, value } = e.target;
        console.log(id, value)
        setFormData((prevData) => ({
            ...prevData,
            [id]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const emailData = {
            ...formData,
            from_name: `${formData.firstName} ${formData.lastName}`,
        };

        emailjs.send(
            process.env.REACT_APP_SERVICE_ID, // your service ID
            process.env.REACT_APP_TEMPLATE_ID, // your template ID
            emailData,
            process.env.REACT_APP_USER_ID // your user ID
        ).then((result) => {
            Swal.fire({
                icon: 'success',
                text: 'Message sent successfully!'
            });
            clearForm()
        }, (error) => {
            console.log(error.text);
            Swal.fire({
                icon: 'error',
                text: 'Failed to send the message, please try again.'
            });
        });
    };

    return (
        <section className="flex flex-col items-center px-20 pt-16 pb-10 w-full shadow-sm max-md:px-5 max-md:mt-10 max-md:max-w-full radiantBg contactP">
            <h2 className="text-7xl font-black text-white max-md:max-w-full max-md:text-4xl">Contact Form</h2>
            <p className="mt-8 text-3xl font-medium text-white max-md:max-w-full">Please let us know your query</p>
            <form onSubmit={handleSubmit} className="justify-between mt-20 ml-4 max-md:mt-10 max-md:max-w-full flex flex-wrap md:flex-nowrap gap-5">
                <div className="flex flex-col w-full md:w-4/12">
                    <label htmlFor="firstName" className="sr-only">First Name</label>
                    <input onChange={handleChange}
                        className="justify-center items-start px-5 py-3 rounded-2xl border border-white border-solid max-md:px-5 max-md:max-w-full"
                        type="text"
                        id="firstName"
                        placeholder="First Name"
                        aria-label="First Name"
                        value={formData.firstName}
                        required
                    />
                    <label htmlFor="lastName" className="sr-only">Last Name</label>
                    <input onChange={handleChange}
                        className="justify-center items-start px-5 py-3 mt-14 rounded-2xl border border-white border-solid max-md:px-5 max-md:mt-10 max-md:max-w-full"
                        type="text"
                        id="lastName"
                        placeholder="Last Name"
                        value={formData.lastName}
                        aria-label="Last Name"
                        required

                    />
                    <label htmlFor="country" className="sr-only">Country</label>
                    <input onChange={handleChange}
                        className="justify-center items-start px-5 py-3 mt-14 whitespace-nowrap rounded-2xl border border-white border-solid max-md:px-5 max-md:mt-10 max-md:max-w-full"
                        type="text"
                        id="country"
                        placeholder="Country"
                        value={formData.country}
                        aria-label="Country"
                        required

                    />
                    <label htmlFor="phoneNumber" className="sr-only">Phone Number</label>
                    <input onChange={handleChange}
                        className="justify-center items-start px-5 py-3 mt-14 rounded-2xl border border-white border-solid max-md:px-5 max-md:mt-10 max-md:max-w-full"
                        type="tel"
                        id="phoneNumber"
                        placeholder="Phone Number"
                        value={formData.phoneNumber}
                        aria-label="Phone Number"
                        required

                    />
                    <label htmlFor="email" className="sr-only">Email</label>
                    <input onChange={handleChange}
                        className="justify-center items-start px-5 py-3 mt-14 whitespace-nowrap rounded-2xl border border-white border-solid max-md:px-5 max-md:mt-10 max-md:max-w-full"
                        type="email"
                        id="email"
                        placeholder="Email"
                        value={formData.email}
                        aria-label="Email"
                        required

                    />
                    <label htmlFor="message" className="sr-only">Message</label>
                    <textarea onChange={handleChange}
                        className="justify-center items-start px-5 py-3 mt-14 whitespace-nowrap rounded-2xl border border-white border-solid max-md:px-5 max-md:mt-10 max-md:max-w-full"
                        id="message"
                        placeholder="Message"
                        value={formData.message}
                        aria-label="Message"
                        required

                    />
                    <button type="submit" className="BuyTPDG justify-center items-center px-7 py-5 mt-7 text-4xl font-black uppercase whitespace-nowrap rounded-3xl shadow-sm text-blue-950 max-md:px-5 max-md:max-w-full">
                        Submit
                    </button>
                </div>
                <div className="flex flex-col w-full md:w-7/12 items-center mt-4 max-md:mt-10 max-md:max-w-full">
                    <p className="self-stretch text-7xl font-black text-center text-white max-md:max-w-full max-md:text-4xl">Let’s grow the community and stay updated</p>
                    <img loading="lazy" src={qrcode} alt="Community Image" className="mt-12 max-w-full aspect-square w-[386px] max-md:mt-10" />
                    <div className="flex gap-5 justify-between mt-24 max-md:mt-10">
                        {/* <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/5751afad9ec3f307c6174641463eebe46110c69badace8d66a2e2616f9c9f585?apiKey=9db28024b9de495c90b2cacb6d403710&" alt="Social Media 1" className="shrink-0 aspect-square w-[87px]" /> */}
                        <Link to='https://x.com/tpdgwtf' className='pointer'>
                            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/5751afad9ec3f307c6174641463eebe46110c69badace8d66a2e2616f9c9f585?apiKey=9db28024b9de495c90b2cacb6d403710&" alt="Social Media 1" className="shrink-0 aspect-square w-[87px]" />
                        </Link>
                    </div>
                </div>
            </form>
        </section>
    )
}

export default Contact